<template>
  <div v-if="canAccess('conceptos_liquidaciones_read')" class="p-grid">
    <div class="p-col-12">
      <div class="card card-w-title">
        <ProgressBar mode="indeterminate" v-if="processing" />

        <DataTable
          :value="items"
          :filters="filters"
          class="p-datatable-responsive-demo p-datatable-sm"
          v-model:selection="currentItem"
          selectionMode="single"
          dataKey="id"
          :paginator="true"
          paginatorPosition="bottom"
          :rows="10"
        >
          <template #header>
            <div class="p-grid p-col-12 p-justify-between">
              <h5 class="p-m-1">Gestionar catalogo liquidaciones</h5>
              <div>
                <span class="p-input-icon-left p-m-1">
                  <i class="pi pi-search" />
                  <InputText
                    v-model="filters['global']"
                    placeholder="Search"
                    class="p-col-12"
                  />
                </span>
                <Button
                  v-if="canAccess('conceptos_liquidaciones_create')"
                  label="Agregar"
                  icon="pi pi-plus"
                  class="p-button-success p-m-1"
                  @click="openNew"
                />
              </div>
            </div>
          </template>

          <Column field="id" header="Código" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Código</span>
              <span class="p-column-value">
                {{ slotProps.data.id }}
              </span>
            </template>
          </Column>
          <Column field="name" header="Nombre" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Nombre</span>
              <span class="p-column-value">
                {{ slotProps.data.name }}
              </span>
            </template>
          </Column>
          <Column field="country.name" header="País" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">País</span>
              <span class="p-column-value">
                {{ slotProps.data.country.name }}
              </span>
            </template>
          </Column>
          <Column field="percentage" header="Porcentaje" :sorteable="true">
            <template #body="slotProps">
              <span class="p-column-title">Porcentaje</span>
              <span class="p-column-value">
                {{ slotProps.data.percentage }}
              </span>
            </template>
          </Column>
          <Column field="settlement_basis" header="Base de Liquidaciones" :sorteable="true">
            <template #body="slotProps">
              <span class="p-column-title">Base de Liquidaciones</span>
              <span class="p-column-value">
                {{ slotProps.data.settlement_basis }}
              </span>
            </template>
          </Column>
          <Column field="is_debit" header="Débito">
            <template #body="slotProps">
              <span class="p-column-title">Débito</span>
              <template v-if="slotProps.data.is_debit === 0">
                <Checkbox :disabled="true" v-model="slotProps.data.is_debit" :binary="false" />
              </template>
              <template v-else-if="slotProps.data.is_debit === 1">
                <Checkbox
                  :disabled="true"
                  v-model="slotProps.data.is_debit"
                  :binary="slotProps.data.is_debit"
                />
              </template>
            </template>
          </Column>
          <Column field="is_credit" header="Crédito">
            <template #body="slotProps">
              <span class="p-column-title">Crédito</span>
              <template v-if="slotProps.data.is_credit === 0">
                <Checkbox
                  :disabled="true"
                  v-model="slotProps.data.is_credit"
                  :binary="false"
                />
              </template>
              <template v-else-if="slotProps.data.is_credit === 1">
                <Checkbox
                  :disabled="true"
                  v-model="slotProps.data.is_credit"
                  :binary="slotProps.data.is_credit"
                />
              </template>
            </template>
          </Column>
          <Column field="apply_last_week" header="Aplica Última Semana">
            <template #body="slotProps">
              <span class="p-column-title">Aplica Última Semana</span>
              <template v-if="slotProps.data.apply_last_week === false">
                <Checkbox
                  :disabled="true"
                  v-model="slotProps.data.apply_last_week"
                  :binary="false"
                />
              </template>
              <template v-else-if="slotProps.data.apply_last_week === true">
                <Checkbox
                  :disabled="true"
                  v-model="slotProps.data.apply_last_week"
                  :binary="slotProps.data.apply_last_week"
                />
              </template>
            </template>
          </Column>
          <Column field="solidarity_fund" header="Fondo de solidaridad">
            <template #body="slotProps">
              <span class="p-column-title">Fondo de solidaridad {{slotProps.data.solidarity_fund}}</span>
              <template v-if="slotProps.data.solidarity_fund === false">
                <Checkbox
                    :disabled="true"
                    v-model="slotProps.data.solidarity_fund"
                    :binary="false"
                />
              </template>
              <template v-else-if="slotProps.data.solidarity_fund === true">
                <Checkbox
                    :disabled="true"
                    v-model="slotProps.data.solidarity_fund"
                    :binary="slotProps.data.solidarity_fund"
                />
              </template>
            </template>
          </Column>
          <Column field="type_register" header="Tipo Registro" :sorteable="true">
            <template #body="slotProps">
              <span class="p-column-title">Tipo Registro</span>
              <span class="p-column-value">
                {{ slotProps.data.type_register }}
              </span>
            </template>
          </Column>
          <Column header="Opciones">
            <template #body="slotProps">
              <span class="p-column-title">Opciones</span>
              <Button
                v-if="canAccess('conceptos_liquidaciones_update')"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="edit(slotProps.data)"
              />
              <Button
                v-if="canAccess('conceptos_liquidaciones_delete')"
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                @click="confirmDelete(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>
        <Dialog
          v-if="
            canAccess('conceptos_liquidaciones_update') ||
              canAccess('conceptos_liquidaciones_create')
          "
          v-model:visible="formDialog"
          :style="{ width: '28.125rem' }"
          header="Fondo de pensión / salud"
          :modal="true"
          class="p-fluid"
        >
          <br />
          <div class="p-field   p-fluid" v-if="currentItem">
            <span class="p-float-label">
              <InputText
                id="name"
                v-model.trim="currentItem.name"
                required="true"
                autofocus
                :class="{ 'p-invalid': submitted && !currentItem.name }"
              />
              <label for="count">Nombre</label>
            </span>
            <small class="p-invalid" v-if="submitted && !currentItem.name"
              >El nombre es requerido</small
            >
          </div>

          <br />
          <div class="p-field   p-fluid" v-if="currentItem">
            <span class="p-float-label">
              <AutoComplete
                id="count"
                :dropdown="true"
                :multiple="false"
                field="name"
                v-model="currentItem.country"
                :suggestions="countries"
                @complete="getCatalogue($event, 'countries', 'name')"
                :class="{ 'p-invalid': submitted && !currentItem.country }"
              />
              <label for="count">País</label>
            </span>
            <small class="p-invalid" v-if="submitted && !currentItem.country"
              >El país es requerido</small
            >
          </div>
          <br />
          <div class="p-field   p-fluid" v-if="currentItem">
            <span class="p-float-label">
              <InputNumber
                mode="decimal"
                :minFractionDigits="2"
                suffix="%"
                :min="0"
                :max="100"
                id="percentage"
                v-model="percentage"
                required="true"
                autofocus
                :class="{ 'p-invalid': submitted && !this.percentage }"
              />
              <label for="count">Porcentaje</label>
            </span>
          </div>
          <br />
          <div class="p-field   p-fluid" v-if="currentItem">
            <span class="p-float-label">
              <InputNumber
                  mode="decimal"
                  :minFractionDigits="2"
                  :min="0"
                  id="settlement_basis"
                  v-model="settlement_basis"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !this.settlement_basis }"
              />
              <label for="settlement_basis">Base de liquidación</label>
            </span>
          </div>
          <br />
          <div class="p-field p-fluid" v-if="currentItem">
            <strong>Tipo:</strong><br /><br />
            <RadioButton name="creditDebit" value="debito" v-model="creditDebit" />
            Débito<br /><br />
            <RadioButton name="creditDebit" value="credito" v-model="creditDebit" /> Crédito
          </div>
          <br />

          <div class="p-field   p-fluid" v-if="currentItem">
            <span class="p-float-label">
              <Dropdown
                id="type_register"
                onchange="isAccrued=false"
                v-model="selectedType"
                :options="typeRegister"
                optionLabel="name"
              />
              <label for="type_register">Tipo</label>
            </span>
          </div>
          <div class="p-field   p-fluid" v-if="selectedType">
            <template v-if="selectedType.code === '2'">
              Aplica en sección Devengado: <Checkbox v-model="isAccrued" :binary="true" />
            </template>
          </div>
          <div class="p-field   p-fluid" v-if="selectedType">
            <template v-if="selectedType.code === '1'">
              Aplica ultima semana: <Checkbox v-model="lastWeek" :binary="true" />
            </template>
          </div>
          <div class="p-field p-fluid" >
            Fondo de solidaridad: <Checkbox v-model="solidarityFund" :binary="true" />
          </div>
          <template #footer>
            <Button
              label="Cancel"
              icon="pi pi-times"
              class="p-button-text"
              @click="hideDialog"
            />
            <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveItem" />
          </template>
        </Dialog>

        <Dialog
          v-if="canAccess('conceptos_liquidaciones_delete')"
          v-model:visible="confirmDeleteModal"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            <span v-if="currentItem">Seguro de eliminar registro?</span>
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="confirmDeleteModal = false"
            />
            <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteRow" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button
        label="Regresar"
        class="p-button-danger p-mr-2"
        @click="$router.push('/admin')"
      />
    </div>
  </div>
</template>

<script src="./Cliquidations.js"></script>
<style src="./Cliquidations.scss" lang="scss" scoped></style>
